<template>
  <div>
    <el-dialog
      :title="row.title"
      :visible.sync="dialogVisible"
      :width="row.type==='special'?'500px':'1280px'"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="form" :inline="row.type==='special'?false:true" label-position="right" label-width="100px" :model="form" :rules="rules">
        <template v-if="row.type==='special'">
          <el-form-item label="费用片段：" prop="feeSnippetCode">
            <el-select
              v-model="form.feeSnippetCode"
              value-key="id"
              filterable
              style="width:100%;"
              @change="change('feesnippet')"
            >
              <el-option v-for="item in feeSnippetList" :key="item.val" :label="item.label" :value="item.val" />
            </el-select>
          </el-form-item>
          <!-- 费用类型动态下拉数据，单选 -->
          <el-form-item label="费用类型：" prop="feeTypeCode">
            <el-select
              v-model="form.feeTypeCode"
              value-key="id"
              filterable
              style="width:100%;"
              @change="change('feetype')"
              @visible-change="getFeeSnippet($event,'feetype')"
            >
              <el-option v-for="item in feeTypeList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </el-form-item>
          <el-form-item label="费用项目：" prop="feeItemCode">
            <el-select
              v-model="form.feeItemCode"
              value-key="id"
              filterable
              style="width:100%;"
              @visible-change="getFeeSnippet($event,'feeItem')"
            >
              <el-option v-for="item in feeItemList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </el-form-item>
          <el-form-item label="费用金额：" prop="feeItemFees">
            <el-input-number
              v-model="form.feeItemFees"
              controls-position="right"
              :precision="2"
              style="width:100%;"
              :step="1"
              @change="handleChange($event,'feeItemFees')"
            />
          </el-form-item>
          <el-form-item label="附件：">
            <input ref="fileId" type="file" @change="getFile"><i v-if="file" class="el-icon-circle-close" @click="clearFile" />
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="承运商：">
            {{ row.carrier }}
          </el-form-item>
          <el-form-item label="结算主体：">
            {{ row.settleEntity }}
          </el-form-item>
          <el-form-item label="币种：" prop="currencyCode">
            <el-select
              v-model="form.currencyCode"
              value-key="id"
              clearable
              filterable
              style="width:100%;"
            >
              <el-option v-for="item in _getAllCommodityDict('CURRENCY_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
            </el-select>
          </el-form-item>
          <el-form-item label="充值金额：" prop="requestAmount">
            <el-input-number
              v-model="form.requestAmount"
              controls-position="right"
              :precision="2"
              style="width:100%;"
              :step="1"
              @change="handleChange($event,'requestAmount')"
            />
          </el-form-item>
          <el-form-item label="税率：" prop="taxRate">
            <!-- 整数 -->
            <el-input-number
              v-model="form.taxRate"
              controls-position="right"
              :precision="0"
              :step="1"
              :min="0"
              @change="handleRateChange($event)"
            />
          </el-form-item>
          <el-form-item label="收款账户" prop="receiveAccount">
            <el-select v-model="form.receiveAccount" placeholder="请选择" filterable :disabled="AccountList.length===1">
              <el-option v-for="item in AccountList" :key="item.id" :label="item.cardNo" :value="item.cardNo" />
            </el-select>
          </el-form-item>
        </template>
      </el-form>
      <Table v-if="row.type==='prepare'" ref="prepareTable" :show-summary="true" :columns="prepareColumns" :table-data="prepareTableData" :get-summaries="getSummaries" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" :loading="Loading" @click="submit">{{ row.type==='special'?'保存':'请款' }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Table from '@/components/Table'
import { prepareColumns } from '@/utils/table-columns.js'
import { specialDetailsReg, feeItemOnSelect, preReChargeAmount, listRegItemByCondition } from '@/api/carriers'
import { getVendorAccountListByVendorCode } from '@/api/select'

export default {
  components: { Table },
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    }},
  data() {
    return {
      AccountList: [],
      prepareTableData: [],
      feeItemList: [],
      feeSnippetList: [],
      feeTypeList: [],
      form: {
        feeItemCode: '',
        feeSnippetCode: '',
        feeTypeCode: '',
        currencyCode: '',
        taxRate: undefined,
        feeItemFees: undefined,
        receiveAccount: ''
      },
      prepareColumns,
      file: '',
      Loading: false,
      rules: {
        feeItemCode: [{ required: true, message: '必填', trigger: 'change' }],
        feeSnippetCode: [{ required: true, message: '必填', trigger: 'change' }],
        feeTypeCode: [{ required: true, message: '必填', trigger: 'change' }],
        feeItemFees: [{ required: true, message: '必填', trigger: 'blur' }],
        currencyCode: [{ required: true, message: '必填', trigger: 'change' }],
        requestAmount: [{ required: true, message: '必填', trigger: 'blur' }],
        taxRate: [{ required: true, message: '必填', trigger: 'blur' }],
        receiveAccount: [{ required: true, message: '必填', trigger: 'change' }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    specialParams() {
      const formDataParams = new FormData()
      const { feeSnippetCode, feeTypeCode, feeItemCode, feeItemFees } = this.form
      const { regId, regNo, regType, regItemId, regItemDetailsId, estimatePayableNo } = this.row
      const nameObj = Object.assign({}, { feeSnippet: this.findName(feeSnippetCode, 'FIN_COST_FRAGMENT') },
        { feeType: this.findName(feeTypeCode, 'FIN_COST_CLASS') }, { feeItem: feeItemCode }
      )
      const regItemSpecialDetailsVO = Object.assign({},
        { regId, regNo, regType, regItemDetailsId, regItemId, estimatePayableNo },
        nameObj,
        { createById: this.$store.getters.userId, createByName: this.$store.getters.name },
        { feeSnippetCode, feeTypeCode, feeItemCode, feeItemFees }
      )
      const jsonContent = Object.assign({}, { regItemSpecialDetailsVO }, { regItemVO: this.row })
      const params = Object.assign({}, { jsonContent: JSON.stringify(jsonContent) }, { file: this.file })
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))
      return formDataParams
    },
    prepareParams() {
      const { settleEntityCode, carrierCode, carrier, settleEntity } = this.row
      const { currencyCode, requestAmount, taxRate, receiveAccount } = this.form
      return Object.assign({}, { settleStatus: 0, createById: this.$store.getters.userId, createByName: this.$store.getters.name },
        { currency: this.findName(currencyCode, 'CURRENCY_TYPE'),
          currencyCode, carrier, carrierCode, settleEntity, settleEntityCode, requestAmount, taxRate, receiveAccount })
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (val) {
        if (this.row.type === 'prepare') {
          this._listRegItemByCondition()
          this.changeAccountList()
        } else {
          this.getFeeList()
        }
      } else {
        this.form = this.$options.data.call(this).form
        if (this.row.type === 'special') {
          this.$refs.fileId.value = ''
          this.file = ''
          this.feeTypeList = []
          this.feeItemList = []
        }
        this.form = this.$options.data.call(this).form
        this.$refs.form.clearValidate()
        this.$refs.form.resetFields()
      }
    }
  },
  mounted() {
  },
  methods: {
    async changeAccountList() {
      const { datas } = await getVendorAccountListByVendorCode({ vendorCode: this.row.carrierCode })
      this.AccountList = datas
      this.$set(this.form, 'receiveAccount', datas.length ? datas[0].cardNo : '')
    },
    handleRateChange(value) {
      if (!value && Number(value) !== 0) {
        this.$nextTick(() => {
          this.$set(this.form, 'taxRate', undefined)
        })
      }
    },
    handleChange(val, flag) {
      if (Number(val) <= 0) {
        this.$nextTick(() => {
          this.$set(this.form, flag, undefined)
        })
      }
    },
    clearFile() {
      this.file = ''
      this.$refs.fileId.value = ''
    },
    async _listRegItemByCondition() {
      const { settleEntityCode, carrierCode, regTypeListStr = '1,0' } = this.row
      const { datas } = await listRegItemByCondition({ settleEntityCode, carrierCode, regTypeListStr })
      this.prepareTableData = datas.filter(item => item.settleStatus + '' === '0')
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['payableFees']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    findName(code, key) {
      const { label } = this._getAllCommodityDict(key).find(item => item.val === code)
      return label
    },
    findCode(label, key) {
      // console.log(this._getAllCommodityDict('FIN_COST_FRAGMENT'))
      const { val } = this._getAllCommodityDict(key).find(item => item.label === label)
      return val
    },
    async getFeeList() {
      const { datas } = await feeItemOnSelect()
      this.FeeList = datas
      const arr = this.row.feeSnippet && this.row.feeSnippet.split(',') || []
      this.feeSnippetList = arr && arr.length && arr.map(item => { return { label: item, val: this.findCode(item, 'FIN_COST_FRAGMENT') } })
    },
    change(flag) {
      if (flag === 'feesnippet') {
        this.feeTypeList = []
        this.feeItemList = []
        this.handleFormChange('form', ['feeTypeCode', 'feeItemCode'])
      }
      if (flag === 'feetype') {
        this.feeItemList = []
        this.handleFormChange('form', ['feeItemCode'])
      }
    },
    handleFormChange(form, clearList) {
      clearList.forEach(key => { this[form][key] = Array.isArray(this[form][key]) ? [] : '' })
    },
    getFeeSnippet(val, flag) {
      if (val) {
        if (flag === 'feetype') {
          if (!this.form.feeSnippetCode) {
            this.$message.warning('请先选择费用片段')
            return
          }
          // console.log(this.FeeList.find(item => item.code === this.row.carrierCode).nextSelectedItems)
          this.feeTypeList = this.FeeList.find(item => item.code === this.row.carrierCode).nextSelectedItems.find(item => item.code === this.form.feeSnippetCode).nextSelectedItems || []
          // console.log(this.feeTypeList)
        }
        if (flag === 'feeItem') {
          if (!this.form.feeTypeCode) {
            this.$message.warning('请先选择费用类型')
            return
          }
          this.feeItemList = this.feeTypeList.find(item => item.code === this.form.feeTypeCode).nextSelectedItems || []
        }
      }
    },
    getFile() {
      const file = this.$refs.fileId.files[0]
      this.file = file
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.Loading = true
            if (this.row.type === 'special') {
              const { code, msg } = await specialDetailsReg(this.specialParams)
              if (code === 0) {
                this.$notify({
                  message: msg,
                  type: 'success'
                })
                this.$emit('input', false)
                this.$emit('refresh')
              }
            } else {
              // console.log(this.prepareParams)
              const { code, msg } = await preReChargeAmount(this.prepareParams)
              if (code === 0) {
                this.$notify({
                  message: msg,
                  type: 'success'
                })
                this.$emit('input', false)
                this.$emit('refresh')
              }
            }
          } finally {
            this.Loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
 /deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>

