<template>
  <div>
    <el-dialog
      title="报价方式选择"
      :visible.sync="dialogVisible"
      width="650px"
      center
      :close-on-click-modal="false"
    >
      <el-row v-for="(item,index) in List" :key="index" class="mb-2" :style="customStyle(item.checkTag)">
        <el-col :span="2"><el-checkbox v-model="item.checkTag" :true-label="1" :false-label="0" @change="changeCheckbox($event,item)" /></el-col>
        <el-col :span="10">
          <el-select v-model="item.feeSnippetGroupNo" value-key="id" :disabled="item.offerNoFlag" @change="changeNumber(item.feeSnippetGroupNo)">
            <el-option
              v-for="(e,i) in numberList"
              :key="i"
              :label="e.label"
              :value="e.value"
              @click.native="changeNumber(item.feeSnippetGroupNo)"
            />
          </el-select>
          <span class="ml-5">{{ item.feeSnippet }}</span>
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="item.onePriceTag" :disabled="item.flag" :true-label="1" :false-label="0" @change="changeOneprice($event,item)">是否一口价</el-checkbox>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetClick">重置</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { List, numberList } from '@/utils/table-columns.js'
import { deepClone, getlabelArr } from '@/utils'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { feeItemOnSelect } from '@/api/carriers'

export default {
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    shipInfo: {
      type: Object,
      default: () => {
        return { }
      }
    }
  },
  data() {
    return {
      List,
      numberList,
      form: {},
      file: '',
      FeeList: []

    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'dialogVisible'(val) {
      this.resetClick()
    }
  },
  mounted() {
    // this.List = this.List.map(item => {
    //   return { ...item, feeSnippetCode: this.findCode(item.feeSnippet, 'FIN_COST_FRAGMENT') }
    // })
  },
  methods: {
    resetClick() {
      this.List = this.List.map((item, index) => { return { ...item, offerNoFlag: false, checkTag: 0, onePriceTag: 0, flag: false, numberFlag: false, feeSnippetGroupNo: index + 1 } })
    },
    customStyle(flag) {
      if (flag) {
        return { background: '#F5F7FA' }
      }
    },
    // FIN_COST_FRAGMENT
    findCode(feeSnippet, key) {
      return this._getAllCommodityDict(key).find(item => item.label === feeSnippet)?.val
    },
    changeOneprice(val, item) {
      if (val) {
        this.$set(item, 'flag', true)
        this.$set(item, 'offerNoFlag', true)
        this.$set(item, 'checkTag', 1)
        // 选是否一口价也要给序号相同的全选
        const arr = this.List.filter(e => (e.feeSnippetGroupNo === item.feeSnippetGroupNo))
        if (arr.length > 1) {
          // 遍历全选中
          arr.map(ee => {
            this.$set(ee, 'checkTag', 1)
            this.$set(ee, 'onePriceTag', 1)
            this.$set(ee, 'flag', true)
            this.$set(ee, 'offerNoFlag', true)
          })
        }
      }
    },
    changeCheckbox(val, item) {
      if (val) {
        this.$set(item, 'flag', true)
        this.$set(item, 'offerNoFlag', true)
        const arr = this.List.filter(e => (e.feeSnippetGroupNo === item.feeSnippetGroupNo))
        // console.log(arr)
        if (arr.length > 1) {
          // 遍历全选中
          arr.map(ee => {
            this.$set(ee, 'checkTag', 1)
            this.$set(ee, 'onePriceTag', 1)
            this.$set(ee, 'flag', true)
            this.$set(ee, 'offerNoFlag', true)
          })
        }
      } else {
        this.$set(item, 'flag', false)
        this.$set(item, 'onePriceTag', 0)
        this.$set(item, 'offerNoFlag', false)
        this.List.filter(e => e.feeSnippetGroupNo === item.feeSnippetGroupNo).map(ee => {
          this.$set(ee, 'checkTag', 0)
          this.$set(ee, 'onePriceTag', 0)
          this.$set(ee, 'flag', false)
          this.$set(ee, 'offerNoFlag', false)
        })
      }
    },
    // 校验已选数据是否能选上承运商，费用类别
    handleCheckValid(arr) {
      // 过滤承运商
      arr.map((item, index) => {
        const arr = item.feeSnippet.split(',')
        const list = this.FeeList.filter(Fee => {
          const flag = arr.map(e => Fee.nextSelectedItems.find(ee => ee.label === e.trim()))
          if (flag.every(item => item)) return true
        }
        )
        if (list.length === 0) {
          this.$message.warning(`【${item.feeSnippet}】 无可选承运商，请核对！`)
        }
        this.$set(item, 'carrierList', list)
      })
      return arr
    },
    async   submit() {
      const value = ['shipNo', 'shipWarehouse', 'destWarehouse', 'ladingNo', 'shipMode', 'destHarbor', 'containerNo', 'qty', 'volume', 'weight', 'leaveHarborDate']
      const label = ['发运单号', '发运仓', '目的仓', '提单号', '运输方式', '目的港', '货柜号', '数量（双）', '体积（ m³）', '重量(kg)', '出港日期']
      sessionStorage.setItem('details', JSON.stringify(this.List))
      let arr = deepClone(this.List).filter(item => item.checkTag)
      const list = []
      let string = ''
      let code = ''
      // console.log(arr)

      arr = arr.map(item => { return { ...item, offerNo: this.shipInfo.shipNo } })
      arr.map(item => {
        // console.log(item)
        const obj = list.find(e => e.feeSnippetGroupNo === item.feeSnippetGroupNo)
        if (obj) {
          // 合并
          string = item.feeSnippet + ',' + obj.feeSnippet
          code = item.feeSnippetCode + ',' + obj.feeSnippetCode
          this.$set(obj, 'feeSnippet', string)
          this.$set(obj, 'feeSnippetCode', code)
        } else {
          list.push(item)
        }
      })
      console.log(list)
      const { datas } = await feeItemOnSelect()
      this.FeeList = datas
      if (this.handleCheckValid(list).find(item => !item.carrierList.length)) {
        return
      }
      if (list.length) {
        this.$emit('input', false)
        this.$router.push({
          path: 'register',
          append: 'true',
          query: { type: 'offer', list: JSON.stringify(list), shipInfo: JSON.stringify(this.shipInfo),
            labelArr: JSON.stringify(getlabelArr(label, value, this.shipInfo))
          }
        })
      } else {
        this.$message.warning('至少选择一种方式')
      }
    },
    changeNumber(val) {
      const arr = this.List.filter(item => (item.feeSnippetGroupNo === val))
      // console.log(arr)
      if (arr.length > 1) {
        // 同一序号出现2个及以上时对应片段的是否一口价默认勾选且不可取消
        arr.map(item => {
          this.$set(item, 'flag', true)
          this.$set(item, 'checkTag', 1)
          this.$set(item, 'onePriceTag', 1)
          this.$set(item, 'offerNoFlag', true)
        })
      } else {
        // 当前序号只有一个
        arr.map(item => {
          item.checkTag && this.$set(item, 'flag', true)
          item.checkTag && this.$set(item, 'offerNoFlag', true)
          !item.checkTag && this.$set(item, 'flag', false)
        })
      }
      // this.List = this.List.sort(this.compare('feeSnippetGroupNo'))
    },
    compare(property) {
      return function(a, b) {
        var value1 = a[property]
        var value2 = b[property]
        return value1 - value2
      }
    }
  }
}
</script>
<style scoped lang="scss">
/deep/.el-select > .el-input {
    display: block;
    width: 90px;
}
/deep/.style  {
  min-width:100px!important;
}
.el-checkbox:last-of-type {
    margin-right: 0;
    margin-top: 8px;
}
</style>

