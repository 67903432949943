<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">

      <el-tab-pane label="未出费用" name="first">
        <el-form ref="noForm" :model="noForm" label-width="90px" style="display: flex">
          <div class="el-lt" style="width: 85%">
            <el-row :span="24" class="row-input" :class="{ 'show-row': showRow1 }">
              <el-col :span="8">
                <el-form-item label="预期货柜号" :class="$i18n.locale">
                  <el-input v-model="noForm.expectContainerNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发运单号" :class="$i18n.locale">
                  <el-input v-model="noForm.shipNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="提单号" :class="$i18n.locale">
                  <el-input v-model="noForm.ladingNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="货柜号" :class="$i18n.locale">
                  <el-input v-model="noForm.containerNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="运输方式" :class="$i18n.locale">
                  <el-select v-model="noForm.shipModeCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option label="空运" value="空运" />
                    <el-option label="快递" value="快递" />
                    <el-option label="铁路" value="铁路" />
                    <el-option label="海运" value="海运" />
                    <el-option label="海外仓" value="海外仓" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发运仓" :class="$i18n.locale">
                  <el-select v-model="noForm.shipWarehouseCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in destWarehouseList"
                      :key="item.warehouseCode"
                      :label="item.warehouseName"
                      :value="item.warehouseCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="目的仓" :class="$i18n.locale">
                  <el-select
                    v-model="noForm.destWarehouseCode"
                    style="width:100%;"
                    filterable
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >
                    <el-option
                      v-for="item in destWarehouseList"
                      :key="item.warehouseCode"
                      :label="item.warehouseName"
                      :value="item.warehouseCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发运日期">
                  <el-date-picker
                    v-model="noForm.value1"
                    type="daterange"
                    range-separator="~"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24">
                <el-button type="primary" @click="handleChangeLogistics(multipleSelection[0],'offer')">物流报价登记
                </el-button>
                <el-button type="primary" @click="showDialgTemplate()">复制模板到报价</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row class="el-rt" style="width: 25%">
            <el-col class="row-center">
              <el-button type="primary" :loading="tableLoading" @click="queryClick()">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
              <el-button type="text" @click="showRow1=!showRow1">{{
                showRow1 ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <Table
          ref="NoBillDataRef"
          :columns="chooseColumns"
          :table-data="NoBillData"
          :loading="tableLoading"
          :single-check="true"
          @handleSelectionChange="handleSelectionChange"
        />
        <div class="block">
          <Paging :pager="pager2" end :page-obj="pager2" @update="pagerUpdateNoForm" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="已出费用" name="second">
        <el-form ref="form" :model="form" label-width="80px" style="display: flex" :rules="rules">
          <div class="el-lt" style="width: 90%;">
            <el-row :span="24" class="row-input" :class="{ 'show-row': showRow2 }">
              <el-col :span="8">
                <el-form-item label="暂估应付单" :class="$i18n.locale" label-width="82px">
                  <el-input v-model="form.estimatePayableNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算主体" prop="settleEntityCode">
                  <el-select
                    v-model="form.settleEntityCode"
                    style="width:100%;"
                    value-key="id"
                    placeholder="请选择"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in cargoOwnerNameList"
                      :key="item.cargoOwnerName"
                      :label="item.cargoOwnerName"
                      :value="item.cargoOwnerCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="承运商" prop="carrierCode">
                  <el-select v-model="form.carrierCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in newCarrierList"
                      :key="item.id"
                      :label="item.vendorName"
                      :value="item.vendorCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="货柜号" :class="$i18n.locale">
                  <el-input v-model="form.containerNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="提单号" :class="$i18n.locale">
                  <el-input v-model="form.ladingNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="运输方式" :class="$i18n.locale">
                  <el-select v-model="form.shipModeCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option label="空运" value="空运" />
                    <el-option label="快递" value="快递" />
                    <el-option label="铁路" value="铁路" />
                    <el-option label="海运" value="海运" />
                    <el-option label="海外仓" value="海外仓" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="币种" :class="$i18n.locale">
                  <el-select v-model="form.currencyCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in _getAllCommodityDict('CURRENCY_TYPE')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="来源单号" :class="$i18n.locale">
                  <el-input v-model="form.shipNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算状态" :class="$i18n.locale">
                  <el-select v-model="form.settleStatus" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_LogisticsSettleStatusEnum')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="对账状态" :class="$i18n.locale">
                  <el-select v-model="form.auditStatus" style="width:100%;" value-key="id" clearable filterable>
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_LogisticsAuditStatusEnum')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="费用片段" :class="$i18n.locale">
                  <el-select v-model="form.feeSnippetCode" style="width:100%;" value-key="id" clearable filterable>
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_COST_FRAGMENT')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="创建日期">
                  <el-date-picker
                    v-model="form.value1"
                    type="daterange"
                    range-separator="~"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24">
                <!-- 单选应付单，已对账单不允许操作 -->
                <el-button type="primary" @click="handleSpecialReg('special',multipleSelection[0])">特殊费用登记</el-button>
                <el-button type="primary" @click="handleSpecialReg('prepare',form)">预充值</el-button>
                <el-button type="primary" @click="handleChangeLogistics(multipleSelection[0],'carriers')">物流费用变更</el-button>
                <el-button type="primary" @click="handleChangeLogistics(multipleSelection[0],'info')">清关信息登记</el-button>
                <el-button type="primary" @click="handleExport">导出</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row class="el-rt" style="width:14%">
            <el-col class="row-center">
              <el-button type="primary" :loading="tableLoading" @click="queryClick()">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
              <el-button type="text" @click="showRow2=!showRow2">{{
                showRow2 ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <Table
          ref="haveBillDataRef"
          :show-summary="true"
          :get-summaries="getSummaries"
          :select-init="selectInit"
          :columns="costColumns"
          :table-data="haveBillData"
          :single-check="true"
          :page-obj="pager1"
          :loading="tableLoading"
          @handleSelectionChange="handleSelectionChange"
        >
          <el-table-column slot="estimatePayableNo" label="暂估应付单" width="180">
            <template slot-scope="scope">
              <el-button type="text" :style="customStyle(scope.row.feeSnippet)" @click="toRedister(scope.row)">{{
                scope.row.estimatePayableNo }}</el-button>
            </template>
          </el-table-column>
          <el-table-column slot="feeSnippet" label="费用片段" width="120">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.feeSnippet&&scope.row.feeSnippet.split(',')" :key="index"> {{ item }}</div>
            </template>
          </el-table-column>
          <el-table-column slot="download" label="操作">
            <template slot-scope="scope">
              <el-button v-if="scope.row.filePath" type="text" @click="downloadByThoms(scope.row.filePath)">下载
              </el-button>
            </template>
          </el-table-column>
        </Table>
        <div class="block">
          <Paging :pager="pager1" end @update="pagerUpdate" />
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 特殊费用登记,预充值 -->
    <Special v-model="regDialogvisible" :row="row" @refresh="queryClick" />
    <Offer v-model="offerMethodvisible" :ship-info="multipleSelection[0]" />
    <el-dialog title="报价模板选择" :visible.sync="dialogVisible" width="750px" center :close-on-click-modal="false">
      <Table
        ref="templateDataRef"
        :columns="templateColumns"
        :table-data="templateDatas"
        :single-check="true"
        @handleSelectionChange="handleTemplateSelectionChange"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" :disabled="templateSelections.length!==1" @click="submit">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog title="货柜关联发运单" :visible.sync="associateDialogVisible" width="750px" center :close-on-click-modal="false">
      <Table
        ref="associateDataRef"
        :columns="associateColumns"
        :table-data="associateDatas"
        :check="true"
        @handleSelectionChange="val=>associateSelections=val"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleRegAgain">重新登记</el-button>
        <el-button type="primary" :loading="associateSubmitLoading" @click="associateSubmit">关联登记</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { costColumns, chooseColumns, templateColumns, associateColumns } from '@/utils/table-columns.js'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Paging'
import Table from '@/components/Table'
import Special from './components/special-register'
import Offer from './components/offer-method'
import { getcargoownerList, basewarehouse, getVendorListByVendorType } from '@/api/select.js'
import { carrierBillExport, listContainerRelateShipInfo, relateReg, downloadByThoms, getShipInfoByCondition, listShipInfoByPage, listOfferMethodTwoLevel, listRegItemByPage, listRegForMoreLevelByCondition, checkHasSpecialDetails, checkHasClearanceReg } from '@/api/carriers'
import { deepClone, getlabelArr } from '@/utils'
import { FloatAdd } from '@/utils/acc'

export default {
  components: { Paging, Table, Special, Offer },
  mixins: [commodityInfoDict],
  data() {
    return {
      type: '',
      templateColumns, associateColumns,
      templateDatas: [],
      dialogVisible: false,
      offerMethodvisible: false,
      costColumns,
      chooseColumns,
      tableLoading: false,
      associateDialogVisible: false,
      regDialogvisible: false,
      cargoOwnerNameList: [],
      pager1: {
        current: 1,
        size: 20,
        total: 0
      },
      pager2: {
        current: 1,
        size: 20,
        total: 0
      },
      NoBillData: [],
      noForm: {
        value1: []

      },
      row: {
        title: ''
      },
      haveBillData: [],
      activeName: 'first',
      form: {
        descs: 'create_time',
        carrierCode: '',
        settleEntityCode: '',
        value1: [],
        regTypeListStr: '1,0',
        currencyCode: ''
      },
      destWarehouseList: [],
      newCarrierList: [],
      showRow1: false,
      showRow2: false,
      multipleSelection: [],
      templateSelections: [],
      associateSelections: [],
      associateDatas: [], associateSubmitLoading: false,
      rules: {
        settleEntityCode: [{ required: true, message: '必填', trigger: 'blur' }],
        carrierCode: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  computed: {
    queryParams() {
      if (this.activeName === 'first') {
        const { value1 } = this.noForm
        const [shipDateStart, shipDateEnd] = value1 || []
        return Object.assign({}, this.noForm, this.pager2, { regStatus: 0, shipDateStart, shipDateEnd })
      } else {
        const { value1 } = this.form
        const [createDateStart, createDateEnd] = value1 || []
        return Object.assign({}, this.form, this.pager1, { createDateStart, createDateEnd })
      }
    },
    exportParams() {
      const { value1 } = this.form
      const [createDateStart, createDateEnd] = value1 || []
      return Object.assign({}, this.form, { createDateStart, createDateEnd })
    }
  },
  created() {
    // 除了登记都返回first Tab页
    let type = ''
    sessionStorage.getItem('type') ? type = JSON.parse(sessionStorage.getItem('type')) : ''
    if (type) {
      if (type === 'offer') {
        this.activeName = 'first'
      } else {
        this.activeName = 'second'
      }
    } else {
      this.activeName = 'first'
    }
  },
  mounted() {
    this._getcargoownerList()
    this._basewarehouse()
    this._getVendorListByVendorType()
    this.queryClick()
  },
  methods: {
    async handleExport() {
      const { datas } = await carrierBillExport(this.exportParams)
      this.download(datas)
    },
    handleRegAgain() {
      if (this.type === 'offer') {
        this.offerMethodvisible = true
      } else {
        this.dialogVisible = true
      }
      this.associateDialogVisible = false
    },
    customStyle(flag) {
      if (flag === '预充值') {
        return {
          color: '#606266',
          cursor: 'default '
        }
      }
    },
    async _basewarehouse() {
      const { datas } = await basewarehouse()
      this.destWarehouseList = datas
    },
    async _getVendorListByVendorType() {
      const { datas } = await getVendorListByVendorType({ vendorType: '6' })
      this.newCarrierList = datas
    },
    toRedister(row) {
      if (row.feeSnippet === '预充值') {
        return
      }
      this.handleChangeLogistics(row, 'view')
    },
    async downloadByThoms(httpUrlString) {
      const res = await downloadByThoms({ httpUrlString })
      console.log(res)
      console.log(new Blob([res]))
      const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream;charset=utf-8' }))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      const arr = httpUrlString.split('/')[httpUrlString.split('/').length - 1].split('.')
      const fileName = arr[0]
      link.setAttribute('download', fileName + '.' + arr[1])
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
    },
    download(url) {
      window.open(url, '_blank')
    },
    async associateSubmit() {
      if (this.associateSelections.length !== 1) {
        this.$message.warning('请选择一条发运单')
        return
      }
      try {
        this.associateSubmitLoading = true
        const formDataParams = new FormData()
        const { id, shipNo } = this.multipleSelection[0]
        const jsonContent = Object.assign({}, this.associateSelections[0], { createById: this.$store.getters.userId, createByName: this.$store.getters.name }, { shipNoRelateTo: this.associateSelections[0].shipNo }, { shipNo, shipInfoId: id })
        formDataParams.set('jsonContent', JSON.stringify(jsonContent))
        const { code, msg } = await relateReg(formDataParams)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.associateDialogVisible = false
          this.queryClick()
        }
      } finally {
        this.associateSubmitLoading = false
      }
    },
    async submit() {
      if (this.templateSelections.length !== 1) {
        this.$message.warning('请选择一条发运单')
        return
      } else {
        sessionStorage.setItem('details', JSON.stringify((this.templateSelections[0].details)))
        const { datas } = await getShipInfoByCondition({ shipNo: this.multipleSelection[0].shipNo })
        datas.logisticsChannel = datas.logisticsChannel || ''
        const shipInfo = datas
        const { details, feeDetails } = this.templateSelections[0]
        let arr = deepClone(details).filter(item => item.checkTag)
        let list = []
        let string = ''
        let code = ''
        const value = ['shipNo', 'shipWarehouse', 'destWarehouse', 'ladingNo', 'shipMode', 'destHarbor', 'containerNo', 'qty', 'volume', 'weight', 'leaveHarborDate']
        const label = ['发运单号', '发运仓', '目的仓', '提单号', '运输方式', '目的港', '货柜号', '数量（双）', '体积（ m³）', '重量(kg)', '出港日期']
        arr = arr.map(item => { return { ...item, offerNo: shipInfo.shipNo } })
        arr.map(item => {
          const obj = list.find(e => e.feeSnippetGroupNo === item.feeSnippetGroupNo)
          if (obj) {
            // 合并
            string = item.feeSnippet + ',' + obj.feeSnippet
            code = item.feeSnippetCode + ',' + obj.feeSnippetCode
            this.$set(obj, 'feeSnippet', string)
            this.$set(obj, 'feeSnippetCode', code)
          } else {
            list.push(item)
          }
        })
        // 回显所有明细
        list = list.map(item => {
          feeDetails.map(i => {
            let totalPrice = 0
            i.regItemDetailsList.map(ii => { totalPrice = totalPrice + ii.feeItemFees })
            if (item.feeSnippetCode.indexOf(i.feeSnippetCode) > -1) {
              this.$set(item, 'currencyCode', i.currencyCode)
              this.$set(item, 'settleEntityCode', i.settleEntityCode)
              this.$set(item, 'carrierCode', i.carrierCode)
              this.$set(item, 'feeTypeCode', i.feeTypeCode)
              this.$set(item, 'regItemDetailsList', i.regItemDetailsList)
              this.$set(item, 'totalPrice', totalPrice)
              this.$set(item, 'costTypeList', [{ code: i.feeTypeCode, label: i.feeType }])
            }
          })
          return { ...item }
        })

        if (list.length) {
          this.$emit('input', false)
          this.$router.push({
            path: 'register',
            append: 'true',
            query: {
              type: 'offer', list: JSON.stringify(list), shipInfo: JSON.stringify(shipInfo),
              labelArr: JSON.stringify(getlabelArr(label, value, shipInfo))
            }
          })
        }
      }
    },
    async showDialgTemplate() {
      // 先限制
      if (this.multipleSelection.length !== 1) {
        this.$message.warning('请选择一条发运单')
        return
      } else {
        this.type = 'temp'
        const { datas: data } = await listOfferMethodTwoLevel({ recordCount: 20 })
        this.templateDatas = data
        const { code, datas = [] } = await listContainerRelateShipInfo(this.multipleSelection[0].containerNo)
        if (code === 0 && datas.length) {
          this.associateDatas = datas
          this.associateDialogVisible = true
        } else {
          this.dialogVisible = true
        }
      }
    },
    handleTemplateSelectionChange(val) {
      this.templateSelections = val
    },
    async handleChangeLogistics(row, type) {
      if (row && row.feeSnippetCode && row.feeSnippetCode === 'FYPD888') {
        this.$message.warning('预充值片段不允许此操作')
        return
      }
      // 未对账允许操作
      const value = ['shipNo', 'shipWarehouse', 'destWarehouse', 'ladingNo', 'shipMode', 'destHarbor', 'containerNo', 'qty', 'volume', 'weight', 'leaveHarborDate']
      const label = ['发运单号', '发运仓', '目的仓', '提单号', '运输方式', '目的港', '货柜号', '数量（双）', '体积（ m³）', '重量(kg)', '出港日期']
      let list = []
      let shipInfo = {}
      const regItemSpecialDetailsList = []
      if (type === 'carriers' || type === 'view') {
        // 有特殊费用的不允许承运商变更
        if (type === 'carriers') {
          if (this.multipleSelection.length !== 1) {
            this.$message.warning('请选择一条暂估应付单,且仅限【未对账】状态操作')
            return
          } else if (row.auditStatus + '' !== '0') {
            this.$message.warning('仅限【未对账】状态操作')
            return
          }
        }
        if (type === 'carriers') {
          const { datas: flag } = await checkHasSpecialDetails(row.id)
          if (flag) {
            this.$message.warning('请选择一条没有特殊费用的暂估应付单')
            return
          }
        }
        const { code, datas } = await listRegForMoreLevelByCondition({ id: row.regId })
        if (code === 0) {
          const obj = deepClone(datas[0])
          delete obj.regItemList
          shipInfo = Object.assign({}, obj, { id: row.id })
          datas[0].regItemList = datas[0].regItemList.filter(item => item.id === row.id).map(item => {
            return { ...item, totalPrice: 0 }
          })
          // console.log(datas[0].regItemList)
          // 回显totalPrice ok
          if (type === 'view' && row.feeSnippet === '关税') {
            // 单独处理至清关登记详情
            const { carrierCode, settleEntityCode, filePath, currencyCode, currency } = datas[0].regItemList[0]
            const { feeItemFees } = datas[0].regItemList[0].regItemDetailsList[0]
            shipInfo = Object.assign({}, shipInfo, { feeItemFees, carrierCode, settleEntityCode, feeSnippet: row.feeSnippet, filePath, currencyCode, currency })
          } else {
            list = datas[0].regItemList.map(item => {
              let sum = 0
              shipInfo.regId = item.regId
              shipInfo.filePath = item.filePath
              shipInfo.feeSnippet = item.feeSnippet
              shipInfo.feeSnippetCode = item.feeSnippetCode
              item.regItemDetailsList.map(e => {
                sum = FloatAdd(sum, e.feeItemFees)
                e.regItemSpecialDetailsList && e.regItemSpecialDetailsList.length && e.regItemSpecialDetailsList.map(ee => regItemSpecialDetailsList.push(ee))
              })
              if (row.feeSnippet === '关税') {
                // 关税变更承运商单独处理下拉数据和shipInfo
                this.$set(item, 'totalPrice', sum)
                this.$set(item, 'costTypeList', [{ label: '关税', code: 'FYPD009' }])
                this.$set(item, 'carrierList', this.newCarrierList.map(item => { return { code: item.vendorCode, label: item.vendorName } }))
              } else if (item.onePriceTag + '' === '1') {
                item.regItemDetailsList = item.regItemDetailsList.filter(e => e.feeItemCode === 'FYXM999')
                // console.log(item.regItemDetailsList)
                this.$set(item, 'totalPrice', sum)
                this.$set(item, 'costTypeList', [{ label: '一口价', code: 'FYX999' }])
              } else {
                // 回显费用类型
                this.$set(item, 'totalPrice', sum)
                this.$set(item, 'costTypeList', this._getAllCommodityDict('FIN_COST_CLASS').map(item => { return { code: item.val, label: item.label } }))
                if (type === 'view' && row.feeSnippetCode === 'FYPD009') {
                  this.$set(item, 'costTypeList', [{ label: '关税', code: 'FYPD009' }])
                }
              }
              return { ...item, regItemSpecialDetailsList }
            })
          }
          // console.log(shipInfo)
          this.$router.push({
            path: 'register',
            append: 'true',
            query: {
              type, list: JSON.stringify(list), shipInfo: JSON.stringify(shipInfo),
              labelArr: JSON.stringify(getlabelArr(label, value, shipInfo))
            }
          })
        }
      }
      if (type === 'offer') {
        if (this.multipleSelection.length === 1) {
          // 选择发运单对应的货柜号获取系统中暂估信息，获取到了弹associateDialogVisible
          this.type = type
          const { code, datas = [] } = await listContainerRelateShipInfo(row.containerNo)
          if (code === 0 && datas.length) {
            this.associateDatas = datas
            this.associateDialogVisible = true
          } else {
            this.offerMethodvisible = true
          }
          return
        } else {
          this.$message.warning('请选择一条发运单')
          return
        }
      }
      if (type === 'info') {
        if (this.multipleSelection.length === 1 && row.feeSnippet.indexOf('报关费用') !== -1) {
          const { code } = await checkHasClearanceReg(row.shipInfoId)
          if (code === 0) {
            const { datas } = await getShipInfoByCondition({ id: row.shipInfoId })
            shipInfo = Object.assign({}, row, datas)
            this.$router.push({
              path: 'register',
              append: 'true',
              query: {
                type, list: JSON.stringify(list), shipInfo: JSON.stringify(shipInfo),
                labelArr: JSON.stringify(getlabelArr(label, value, shipInfo))
              }
            })
          }
        } else {
          this.$message.warning('选择一个“报关费用”的暂估应付单')
          return
        }
      }
    },
    findName(code) {
      const obj = this.newCarrierList.find(item => String(item.vendorCode) === String(code)) || []
      return obj ? obj.vendorName : ''
    },
    handleSpecialReg(type, row) {
      if (row && row.feeSnippetCode === 'FYPD888') {
        this.$message.warning('预充值片段不允许操作')
        return
      }

      if (type === 'special') {
        // 已对账单不允许（仅限未对账）
        if (this.multipleSelection.length === 1 && row.auditStatus + '' === '0') {
          if (row.feeSnippetCode === 'FYPD009') {
            // 关税不允许特殊费用登记
            this.$message.warning('关税片段不允许登记特殊费用')
            return
          } else {
            this.row = Object.assign({ title: '特殊费用登记', type }, row,)
            this.regDialogvisible = true
          }
        } else {
          this.$message.warning('请选择一条单据，仅限【未对账】状态操作')
          return
        }
      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            const { cargoOwnerName: settleEntity } = this.cargoOwnerNameList.find(item => item.cargoOwnerCode === row.settleEntityCode)
            this.row = Object.assign({ title: '预充值', type }, { carrierCode: row.carrierCode, settleEntityCode: row.settleEntityCode }, { settleEntity, carrier: this.findName(row.carrierCode) })
            this.regDialogvisible = true
          } else {
            this.$message.warning('请先选择承运商、结算主体')
          }
        })
      }
    },
    handleSelectionChange(val) {
      /* if (val.length > 1) {
          if (this.activeName === 'first') {
            this.$refs.NoBillDataRef.$refs.table.clearSelection()
            this.$refs.NoBillDataRef.$refs.table.toggleRowSelection(val.pop())
          } else {
            this.$refs.haveBillDataRef.$refs.table.clearSelection()
            this.$refs.haveBillDataRef.$refs.table.toggleRowSelection(val.pop())
          }
        } else {
          this.multipleSelection = val
        } */
      this.multipleSelection = val
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['payableFees']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    selectInit(row, index) {
      if (row.settleStatus + '' === '100') {
        return false
      } else {
        return true
      }
    },
    queryClick() {
      this.activeName === 'first' ? this.pager2.current = 1 : this.pager1.current = 1
      this.handleQuery(this.queryParams)
    },
    async handleQuery() {
      if (this.activeName === 'first') {
        try {
          this.tableLoading = true
          const { code, datas: { pager, records }} = await listShipInfoByPage(this.queryParams)
          if (code === 0) {
            this.pager2 = pager
            this.NoBillData = records.map(item => { return { ...item, ship: '已发运', logisticsChannel: item.logisticsChannel || '' } })
          }
        } finally {
          this.tableLoading = false
          this.$refs.NoBillDataRef.clearSingleCheck()
        }
      } else {
        this.$refs.form.clearValidate()
        try {
          this.tableLoading = true
          const { code, datas: { pager, records }} = await listRegItemByPage(this.queryParams)
          if (code === 0) {
            this.pager1 = pager
            this.haveBillData = records.map(item => {
              return {
                ...item, auditStatusI8: this.getDicLabel('FIN_LogisticsAuditStatusEnum', item.auditStatus),
                settleStatusI18: this.getDicLabel('FIN_LogisticsSettleStatusEnum', item.settleStatus)
              }
            })
          }
        } finally {
          this.tableLoading = false
          this.$refs.haveBillDataRef.clearSingleCheck()
        }
      }
      sessionStorage.removeItem('type')
    },
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    pagerUpdate(val) {
      this.pager1 = val
      this.handleQuery(this.queryParams)
    },
    pagerUpdateNoForm(val) {
      this.pager2 = val
      this.handleQuery(this.queryParams)
    },
    resetClick() {
      if (this.activeName === 'first') {
        this.noForm = this.$options.data.call(this).noForm
      } else {
        this.form = this.$options.data.call(this).form
        this.$refs.form.clearValidate()
      }
      this.queryClick()
    },
    handleClick(val) {
      sessionStorage.removeItem('type')
      if (val.name === 'first') {
        this.$refs.haveBillDataRef.clearSingleCheck()
      } else {
        this.$refs.NoBillDataRef.clearSingleCheck()
      }
      this.multipleSelection = []
      this.queryClick()
    },

    selectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style scoped lang="scss">
  /deep/ .el-date-editor .el-range-input {
    width: 77px !important;
  }

  /deep/.el-dialog__title {
    font-weight: 500;
    font-family: normal;
    letter-spacing: 1px;
    font-size: 20px;

  }

  /deep/ .el-table__header-wrapper .el-checkbox {
    //找到表头那一行，然后把里面的复选框隐藏掉
    display: none
  }
  .row-input {
  height: 116px;
  overflow: hidden;
}
</style>
